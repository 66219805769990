import React, { useEffect } from "react"
import $ from "jquery"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GetPageURL } from "../common/site/utils"
import "./Header.scss"
import { Accordion } from "react-bootstrap"

const MenuData = () => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        burgerMenus(sort: "Sort:asc") {
          label
          custom_link
          menu {
            slug
            id
            parent {
              slug
              parent {
                slug
              }
            }
          }
          add_menu {
            label
            custom_link
            menu {
              id
              slug
              parent {
                slug
                parent {
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)

  const allmenus = data.glstrapi.burgerMenus

  return (
    <Accordion className="menu-accordian">
      {allmenus &&
        allmenus.map((item, index) => {
          const href =
            item.custom_link === "javascript:void(0)"
              ? { href: item.custom_link }
              : {
                  to: GetPageURL(item.menu, item.custom_link),
                }

          const hasSubMenu = item.add_menu && item.add_menu.length > 0
          return (
            <>
              <Accordion.Item eventKey={index}>
                <Accordion.Header
                  className={`${hasSubMenu ? "sub-menu-exist" : "no-submenu"}`}
                >
                  <Link className="menu-link" {...href}>
                    {item.label}
                  </Link>
                </Accordion.Header>
                {hasSubMenu && (
                  <Accordion.Body>
                    <div className="submenu-list">
                      {item.add_menu.map(submenu => {
                        return (
                          <Link
                            to={GetPageURL(submenu.menu, submenu.custom_link)}
                            className="submenu-link"
                          >
                            {submenu.label}
                          </Link>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </>
          )
        })}
    </Accordion>
  )
}

export default MenuData
