import { graphql, Link, useStaticQuery } from "gatsby"
import React,{useState,useEffect} from "react"
import { Container } from "react-bootstrap"
import { listYourProperty, propertyForSaleURL } from "../../site/urls"
import { GetPageURL } from "../common/site/utils"
import "./Footer.scss"
import SearchResultsContainer from "../SearchResults/SearchResultsContainer";
import sbLogo from "../../images/icons/sb-logo.svg"
import StaticPopularSearch from "../StaticPopularSearch/StaticPopularSearch"
import CookieConsent from "react-cookie-consent"
import $ from "jquery"
import CustomScriptComponent from "../CustomScriptComponent/CustomScriptComponent"

const Footer = ({ pageLayout,categoryType,GQLPage }) => {
  const [showPopular, setShowPopular]=useState(false)
  const data = useStaticQuery(graphql`
    query getFooterLinks {
      glstrapi {
        socialLink {
          footer_menu {
            title
            id
            footer_links {
              target
              link_type
              label
              id
              custom_link
              menu_item {
                ...menuData
              }
            }
          }
        }
        siteConfig {
          instagram_link
          linkedin_link
          pinterest_link
          twitter_link
          youtube_link
          facebook_link
          ...emailPhone
        }
        propertySearch {
          category1
          category2
          category3
        }
      }
    }
  `)
  

  const footerMenuSections = data?.glstrapi.socialLink.footer_menu

  const siteConfig = data?.glstrapi.siteConfig

  const isResultsPage = pageLayout === "search-results-page"
  const CustomContainer = isResultsPage ? SearchResultsContainer : Container

  const currentYear = new Date().getFullYear()
  const popularsearchData = data.glstrapi.propertySearch;

   // Cookie Trigger
   useEffect(() => {
    setTimeout(function(){
      if ($('.cookie-popup .CookieConsent').length > 0) { 
        $('.footer-wrapper').addClass('with_cookie-popup');
      } 
      $('.cookieok').click(function(event) {
        $('.acceptcookie').click();
      }); 
    }, 500);
  }, [])
//  
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(()=>{
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  },[])

  return (
    <div className="footer-wrapper">
      <CustomContainer className="footer-container">
        <div className="top-section">
          <h2 className="title">start your journey with allegiance</h2>
          <div className="cta-section">
            <Link to={propertyForSaleURL} className="button button-white">
              Find property
            </Link>
            <Link to={listYourProperty} className="button button-white-outline">
              List property
            </Link>
          </div>
        </div>
        <div className="footer-divider" />
        {categoryType && renderComponent && (
            <div className="popular-search-component">
              <div className="popular-search-section" onClick={()=>setShowPopular(!showPopular)}>
                <h4 className="title">Popular Search</h4>
              <div className="show-links" ><i className={!showPopular?"icon icon-whity-plus":"icon icon-blacky-minus"}></i></div>
              </div>
              {categoryType === "category1" && (
                <StaticPopularSearch category={popularsearchData?.category1} showPopular={showPopular} GQLPage={GQLPage}/>
              )}
              {categoryType === "category2" && (
                <StaticPopularSearch category={popularsearchData?.category2} showPopular={showPopular} />
              )}
              {categoryType === "category3" && (
                <StaticPopularSearch category={popularsearchData?.category3} showPopular={showPopular}/>
              )}
            </div>
          )}

        <div className="footer-divider" />
        <div className="middle-section">
          {footerMenuSections.map(menuSection => (
            <div className="footer-menu-section" key={menuSection.id}>
              <h4 className="menu-title">{menuSection.title}</h4>
              <div className="menu-links-section">
                {menuSection.footer_links.map(link => (
                  <Link
                    to={GetPageURL(link.menu_item, link.custom_link)}
                    className="menu-link"
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="footer-divider divider-sm" />
        <div className="bottom-section">
          <div className="icon-section">
            <a
              href={siteConfig.facebook_link}
              target="_blank"
              className="icon icon-fb"
            />
            <a
              href={siteConfig.instagram_link}
              target="_blank"
              className="icon icon-insta"
            />
            {/* <a
              href={siteConfig.twitter_link}
              target="_blank"
              className="icon icon-twitter"
            /> */}
            <a
              href={siteConfig.linkedin_link}
              target="_blank"
              className="icon icon-linkedin"
            />
            <a
              href={siteConfig.youtube_link}
              target="_blank"
              className="icon icon-youtube"
            />
          </div>
          <div className="site-by-section">
            <p className="copyright">
              © {currentYear} Allegiance Real Estate. All Rights Reserved.
            </p>
            <p className="site-by">
              Site by{" "}
              <a href="https://starberry.tv/" target={"_blank"}>
                <img src={sbLogo} alt="Starberry Logo" />
              </a>
            </p>
          </div>
        </div>
        <section className="cookie-popup">
        <CookieConsent
              location="bottom"
              buttonText="Accept Cookies"
              cookieName="CookiePolicy"
              buttonClasses="acceptcookie"
              overlay={false}
              // acceptOnScrollPercentage={1}
              //onAccept={()=> ClosePopup()}
          >
              <div className="topbar-block">
              {renderComponent &&
                  <p>

                  We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to='/cookie-policy/'>Cookie Policy</Link>. <a href="javascript:void(0);" class="cookieok">Accept Cookies</a>
                  </p>
              }
              </div>
          </CookieConsent>
      </section>
      <CustomScriptComponent/>
      </CustomContainer>
    </div>
  )
}

export default Footer
