import React, { useEffect, useState } from "react"
import "./Header.scss"
import logoWhite from "../../images/logo-white.svg"
import iconSearch from "../../images/icons/icon-search.svg"
import iconHome from "../../images/icons/icon-home.svg"
import iconBurger from "../../images/icons/icon-burger.svg"
import iconWhatsApp from "../../images/icons/icon-whatsapp.svg"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import BurgerMenu from "./BurgerMenu"
import useHasScrolled from "../../hooks/useHasScrolled"
import clsx from "clsx"
import { listYourProperty } from "../../site/urls"
import SearchResultsContainer from "../SearchResults/SearchResultsContainer"
import SearchBox from "../SearchBox/SearchBox"
import { getWhatsAppURL } from "../common/site/utils"

const Header = props => {
  const { pageLayout, Header_Type, showBugherd } = props

  const { isMobile, isTablet } = useDeviceMedia()

  const [showMenu, setShowMenu] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  const scrolled = useHasScrolled()

  const isResultsPage = pageLayout === "search-results-page"

  const hasWhiteBG =
    pageLayout == "landing_template"
      ? scrolled
      : Header_Type === "Transparent"
      ? scrolled
      : true

      const [renderComponent, setRenderComponent] = useState(false);

      useEffect(()=>{
        window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
            setRenderComponent(true)
          }
        })
        window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
            setRenderComponent(true)
          }
        })
        window.addEventListener("keypress", () => {
          if (renderComponent === false) {
            setRenderComponent(true)
          }
        })
      },[])

  const CustomContainer = isResultsPage ? SearchResultsContainer : Container

  useEffect(() => {
    typeof window != 'undefined' && window.scrollTo(0, 0);

    let gtagScript;

    if(typeof window !== "undefined"){
      // Load Google Tag Manager script
      gtagScript = document.createElement('script');
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-659501913';
      gtagScript.async = true;
      document.head.insertBefore(gtagScript, document.head.firstChild);

      gtagScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        const gtag = function () { window.dataLayer.push(arguments); };
        gtag('js', new Date());
        gtag('config', 'AW-659501913');
      };
      // Load Google Tag Manager script

      const script = document.createElement('script');
      script.id = 'seona-js-plugin';
      script.setAttribute('defer','');
      script.src = 'https://assets.usestyle.ai/seonajsplugin';
      script.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    return () => {
      if (gtagScript) {
        document.head.removeChild(gtagScript);
      }
    };
  }, [])

  return (
    <>
      <div
        className={clsx("header-wrap", {
          "header-white-bg": hasWhiteBG,
          "header-scrolled": scrolled,
        })}
      >
        <BurgerMenu showMenu={showMenu} setShowMenu={setShowMenu} />
        <CustomContainer className="header-container">
          <Link to="/">
            <img width="100%" height="auto" src={logoWhite} alt="logo" className="logo" />
          </Link>
          <div className="menu-section">
            {isMobile && (
                 <a href={getWhatsAppURL("971564144147")} target="_blank" className="menu-item">
                  <img src={iconWhatsApp} alt="logo" className="menu-icon" />
                </a>
            )}
            <div className="menu-item-wrap">
              <button
                className="menu-item"
                onClick={() => setShowSearch(prev => !prev)}
              >
                <img src={iconSearch} alt="menu-icon" className="menu-icon" />
                {!isMobile && (
                  <span>{isTablet ? "search" : "property search"}</span>
                )}
              </button>
              {showSearch && renderComponent && (
                <div className="header-search-box">
                  <SearchBox />
                </div>
              )}
            </div>
            <div className="divider" />
            {!isMobile && (
              <Link to={listYourProperty}>
                <button className="menu-item">
                  <img src={iconHome} alt="menu-icon" className="menu-icon" />
                  <span>list your property</span>
                </button>
              </Link>
            )}
            <div className="divider" />
            <button onClick={() => setShowMenu(true)} className="menu-item">
              <img src={iconBurger} alt="menu-icon" className="menu-icon" />
              {!isMobile && <span>menu</span>}
            </button>
          </div>
        </CustomContainer>
      </div>
      {showSearch && (
        <div
          className="header-overlay"
          onClick={() => setShowSearch(false)}
        ></div>
      )}
    </>
  )
}

export default Header
