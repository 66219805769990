import _ from "lodash"
import React from "react"
import iconClose from "../../../images/icons/icon-close-white.svg"

const SelectedAreaList = ({
  selectedAreas,
  handleRemoveArea,
  handleRemoveAllAreas,
}) => {
  if (_.isEmpty(selectedAreas)) return null

  return (
    <div className="selected-areas-wrap">
      {selectedAreas.map((area, index) => (
        <div className="selected-area" key={index}>
          <span>{area.name}</span>
          <button
            className="remove-area-btn"
            onClick={() => handleRemoveArea(area)}
          >
            <img src={iconClose} alt="close-icon" />
          </button>
        </div>
      ))}

      <button className=" clear-all-btn" onClick={() => handleRemoveAllAreas()}>
        Clear All
      </button>
    </div>
  )
}

export default SelectedAreaList
