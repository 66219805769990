import { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

const useAreaMutiSelect = searchState => {
  const [areasSuggestionList, setAreasSuggestionList] = useState([])
  const [selectedAreas, setSelectedAreas] = useState([])
  const areaData = useStaticQuery(graphql`
    {
      areajson {
        areas {
          slug
          name
          level
        }
      }
    }
  `)
  useEffect(() => {
    const getAreas = async (areasJson) => {
      // const areas = await fetch(
      //   "https://allegiance-strapi.q.starberry.com/areas.json"
      // )
      // const areasJson = await areas.json()
      setAreasSuggestionList(areasJson)
      const searchStateAreas = searchState?.refinementList?.search_areas
      const arrAreas =
        typeof searchStateAreas === "string"
          ? [searchStateAreas]
          : searchStateAreas

      const filteredAreas = areasJson.filter(areaJson => {
        const areaFound = arrAreas?.find(area => areaJson.slug === area)
        return areaFound
      })
      setSelectedAreas(filteredAreas)
    }
    getAreas(areaData.areajson.areas)
  }, [areaData])

  const handleSelectArea = area => {
    const newSelectedAreas = [...selectedAreas]
    const index = newSelectedAreas.findIndex(
      selectedArea => selectedArea.slug === area.slug
    )
    if (index === -1) {
      newSelectedAreas.push(area)
    }
    setSelectedAreas(newSelectedAreas)
    
  }

  const handleRemoveArea = area => {
    const newSelectedAreas = [...selectedAreas]
    const index = newSelectedAreas.findIndex(
      selectedArea => selectedArea.slug === area.slug
    )
    if (index !== -1) {
      newSelectedAreas.splice(index, 1)
    }
    setSelectedAreas(newSelectedAreas)
  }

  const handleRemoveAllAreas = () => {
    setSelectedAreas([])
  }

  return {
    selectedAreas,
    areasSuggestionList,
    handleSelectArea,
    handleRemoveArea,
    handleRemoveAllAreas,
  }
}

export default useAreaMutiSelect
