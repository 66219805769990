import React,{useEffect} from 'react'
import parse from "html-react-parser"

const StaticPopularSearch = ({ category, showPopular,GQLPage }) => {

  useEffect(()=>{

    if(showPopular){
    let getHomePageId=document.getElementById("home-hide-contact");
    let getContactPageId=document.getElementById("contact-hide-home");
    
    if(GQLPage?.alias==="Home"){
      if(getHomePageId){
          getHomePageId.remove()
      }
    }
    else{
      if(getContactPageId){
          getContactPageId.remove()
      }
    }
  }

  },[showPopular])
 

  return (
    <>
      {showPopular && (
        <div className={`popular-search-list`}>{parse(category)}</div>
      )}
    </>
  )
}

export default StaticPopularSearch