export const listPropertURL = "#"
export const privacyURL = "/privacy-policy/"
export const termsURL = "/terms-and-conditions/"
export const aboutURL = "/about/"
export const teamsURL = aboutURL + "meet-the-team/"
export const newsURL = aboutURL + "media/"
export const buyURL = "/buy/"
export const careerURL = aboutURL + "careers/"
export const sellURL = "/sell/"
export const bookValuation = sellURL + "book-valuation/"
export const listYourProperty = sellURL + "list-your-property/"
export const areaGuide = buyURL + "dubai-communities/"
export const contactURL = "/contact-allegiance/"
export const propertyForSaleURL = "/properties/for-sale/"
export const propertyForRentURL = "/properties/for-rent/"
export const propertyForSaleDetailURL = "/property-for-sale/"
export const propertyForRentDetailURL = "/property-for-rent/"
export const offplanForSaleURL = "/off-plan-properties/for-sale/"
export const offplanForSaleDetailURL = "/off-plan-property-for-sale/"
export const bookViewing = "/book-a-viewing/"
export const defaultOffplanURL = "/off-plan-properties/for-sale/in-dubai/"
