import React from "react"
import { Container } from "react-bootstrap"
import Offcanvas from "react-bootstrap/Offcanvas"
import logoWhite from "../../images/logo-white.svg"
import isconClose from "../../images/icons/icon-close-white.svg"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GetPageURL } from "../common/site/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import MenuData from "./MenuData"
import _ from "lodash"
import triangle from "../../images/menu_triangle.svg"

const BurgerMenu = ({ showMenu, setShowMenu }) => {
  const data = useStaticQuery(graphql`
    query getHeaderMenu {
      glstrapi {
        socialLink {
          primary_menu {
            id
            menu_items {
              label
              link_type
              target
              id
              custom_link
              menu_item {
                ...menuData
              }
            }
            sub_menu_item {
              link_type
              label
              id
              custom_link
              target
              menu_item {
                ...menuData
              }
            }
          }
        }
      }
    }
  `)

  const menuItems = data.glstrapi.socialLink.primary_menu

  const { isTablet } = useDeviceMedia()

  const CloseButton = (
    <button onClick={() => setShowMenu(false)} className="close-button">
      <img
        src={isconClose}
        alt="button-close-icon"
        className="button-close-icon"
      />
      <span>close</span>
    </button>
  )

  return (
    <>
      {isTablet ? (
        <Offcanvas
          show={showMenu}
          onHide={() => setShowMenu(false)}
          className="mobile-burger-menu"
        >
          <Offcanvas.Header>
            <img src={logoWhite} alt="logo" className="logo" />
            {CloseButton}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <MenuData />
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <Offcanvas
          show={showMenu}
          onHide={() => setShowMenu(false)}
          placement="top"
          className="menu-canvas"
        >
          <Offcanvas.Body>
            <Container className="header-container menu-canvas-container">
              <img src={logoWhite} alt="logo" className="logo" />
              <div className="canvas-menu-items">
                {menuItems.map(item => {
                  const href =
                    item.menu_items?.custom_link === "javascript:void(0)"
                      ? { href: item.menu_items?.custom_link }
                      : {
                          to: GetPageURL(
                            item.menu_items?.menu_item,
                            item.menu_items?.custom_link
                          ),
                        }
                  return (
                    <div key={item.id} className="canvas-menu-item-link">
                      <Link {...href} className="canvas-menu-item-link">
                        <span>{item.menu_items.label}</span>
                      </Link>
                      {!_.isEmpty(item.sub_menu_item) && (
                        <div className="submenu-wrap">
                          <div className="submenu-container">
                            <img
                              src={triangle}
                              alt="menu-triangle"
                              className="menu-triangle"
                            />
                            {item.sub_menu_item.map((subMenuItem, i) => (
                              <div className="sub-menu-item-wrap">
                                <Link
                                  to={GetPageURL(
                                    subMenuItem.menu_item,
                                    subMenuItem.custom_link
                                  )}
                                  className="sub-menu-item"
                                >
                                  {subMenuItem.label}
                                </Link>
                                {i < item.sub_menu_item.length - 1 && (
                                  <div className="divider"></div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              {CloseButton}
            </Container>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  )
}

export default BurgerMenu
