import React, {useEffect, useState} from "react"
import { Helmet } from "react-helmet"

const CustomScriptComponent = () => {
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(()=>{
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  },[])

  return (
    <>
      { renderComponent ? (
        <Helmet> 
          <script async="" type="text/javascript" src="https://www.allagents.co.uk/widgets/ratings.js"></script>
          <script async="" src="https://www.googletagmanager.com/gtm.js?id=GTM-NDVJLDP"></script>
          <script>{`window.dataLayer = window.dataLayer || [];window.dataLayer.push({"platform":"gatsby"}); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', 'GTM-NDVJLDP');`}</script>
        </Helmet>
      ) : null}
    </>
  )
}

export default CustomScriptComponent
