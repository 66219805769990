import clsx from "clsx"
import React from "react"

const SearchResultsContainer = ({ className, children }) => {
  return (
    <div className={clsx("search-results-container container", className)}>
      {children}
    </div>
  )
}

export default SearchResultsContainer
