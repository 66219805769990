import { isEmpty, forEach } from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import * as qs from "query-string"
import clsx from "clsx"
import axios from "axios"

export const scrollToElement = (element, offset = 1) => {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  typeof window !== "undefined" &&
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
}

export const getStrapiDataFromURL = async url => {
  if (!url) return null

  const getData = () =>
    axios.get(`${process.env.GATSBY_STRAPI_SRC}${url}`, {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      },
    })
  const res = await getData()

  return res?.data
}

export const getWhatsAppURL = number => {
  if (!number) return null
  // remove everything except numbers
  const formatedNumber = number?.replace(/\D/g, "")

  return `https://api.whatsapp.com/send?phone=${formatedNumber}`
}

export const sortItems = (a, b, sortType = 'asc') => {
  if (a === null || a === undefined) {
    return 1;
  }
  if (b === null || b === undefined) {
    return -1;
  }
  if(sortType === 'asc'){
    return a - b;
  } else {
    return b - a;
  }
}

export const numberFormat = num => {
  if (!num) return 0
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
  return new Intl.NumberFormat("en-US", {}).format(num)
}

export const filterNumber = str => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

/**
 * Retal yeild calculation
 */
export const rentalYield = (rate, rent) => {
  if (isNaN(rate) || isNaN(rent) || !rent || !rate) {
    return 0
  }

  if (rate === 0 || rent === 0) {
    return 0
  } else {
    return (rent / rate) * 100
  }
}

export const calculatePrinciple = (
  propertyValue,
  interestRate,
  downPayment,
  loanTerm
) => {
  const monthlyRate = interestRate / 100 / 12
  const payments = 12 * loanTerm

  const x = Math.pow(1 + monthlyRate, payments)
  //console.log("calculate_principle", propertyValue, interestRate, downPayment, loanTerm);

  //var calculate_principle = roundNumber(((propertyValue - (propertyValue * downPayment) / 100) * x * monthlyRate) / (x - 1));
  var calculate_principle = roundNumber(
    ((propertyValue - downPayment) * x * monthlyRate) / (x - 1)
  )

  return calculate_principle
}

export const calculateMonthlyPayment = (
  propertyValue,
  interestRate,
  downPayment,
  loanTerm
) => {
  //console.log("calculate_payment", propertyValue, interestRate, downPayment, loanTerm);
  var calculate_payment = roundNumber(
    calculatePrinciple(propertyValue, interestRate, downPayment, loanTerm)
  )

  return calculate_payment
}

export const roundNumber = num => {
  return Math.round(num * 100) / 100
}

export const getThemeClass = theme => {
  return clsx(
    theme === "white" || theme == null ? "section-m" : "section-p",
    theme && `bg-${theme}`
  )
}

export const getBreadcrumbUrls = menuData => {
  let urls = []
  let current = menuData
  if (current.parent) {
    urls.push({
      label: current.parent.label,
      url: "/" + current.parent.slug + "/",
    })
  urls.push({ label: current.label, url: "/" + current?.parent?.slug + "/" + current?.slug + "/" })
    
  }
  else{
    if(!current.parent){
      urls.push({
        label: current.label,
        url: "/" + current.slug + "/",
      })
    }
    else{
    urls.push({
      label: current.parent?.label,
      url: "/" + current.parent?.slug + "/",
    })
  }
  }

  // urls.push({ label: current.label, url: "/" + current.slug + "/" })
  return urls

  // while (current) {
  //   let parentUrl = urls.length > 0 ? urls[urls.length - 1].url : ""
  //   urls.push({ label: current.label, url: parentUrl + "/" + current.slug })
  //   current = current.parent
  // }
  // return urls.reverse()
}

/** Get the menu page url */
export const GetPageURL = (data = null, manualLink = null) => {
  var currentURL = "/"
  if (!isEmpty(data)) {
    currentURL = `/${data?.slug}`
    if (data.slug === "home" || data.slug === null) {
      currentURL = "/"
    }
    if (!isEmpty(data.parent)) {
      if (isNotEmpty(data?.parent?.parent?.parent)) {
        return `/${data.parent?.parent?.parent?.slug}/${data.parent?.parent?.slug}/${data.parent?.slug}${currentURL}/`
      } else if (data?.parent?.parent !== null) {
        return `/${data.parent?.parent?.slug}/${data.parent?.slug}${currentURL}/`
      }
      return `/${data.parent.slug}${currentURL}/`
    } else {
      if (endsWith(currentURL, "/")) {
        return `${currentURL}`
      } else {
        return `${currentURL}/`
      }
    }
  }

  if (!isEmpty(manualLink)) {
    if (startsWith(manualLink, "/")) {
      return manualLink
    }
    return `/${manualLink}`
  }

  return currentURL
}

/**Start with */
export const startsWith = (str, word) => {
  return str?.lastIndexOf(word, 0) === 0
}
// Altname
export const AltSiteName = " - White & Co"
/**Eng with */
export const endsWith = (str, word) => {
  return str?.slice(-1) === word
}
export const isNotEmpty = val => {
  if (typeof val != "undefined" && val !== null && val !== "") {
    return true
  }
  return false
}

/* Page data to build breadcumbs */
export const PageBreadcrumbs = (slug, staticList = []) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        menus {
          slug
          label
          parent {
            slug
            label
            parent {
              slug
              label
              parent {
                slug
                label
              }
            }
          }
          page {
            id
          }
        }
      }
    }
  `)

  var PageURL = data.glstrapi.menus?.filter(item => item.slug == slug)?.pop()

  var breadcrumbs = []
  if (!isEmpty(PageURL)) {
    // if (!isEmpty(PageURL?.parent?.parent?.parent)) {
    //   breadcrumbs.push({ label: PageURL?.parent?.parent?.parent?.label, url: `/${PageURL?.parent?.parent?.parent?.slug}` })
    //   breadcrumbs.push({ label: PageURL?.parent?.parent?.label, url: `/${PageURL?.parent?.parent?.parent?.slug}/${PageURL?.parent?.parent?.slug}` })
    //   breadcrumbs.push({ label: PageURL?.parent?.label, url: `/${PageURL?.parent?.parent?.parent?.slug}/${PageURL?.parent?.parent?.slug}/${PageURL?.parent?.slug}` })
    // }
    if (!isEmpty(PageURL?.parent?.parent)) {
      breadcrumbs.push({
        label: PageURL?.parent?.parent?.label,
        url: `/${PageURL?.parent?.parent?.slug}/`,
      })
      breadcrumbs.push({
        label: PageURL?.parent?.label,
        url: `/${PageURL?.parent?.parent?.slug}/${PageURL?.parent?.slug}/`,
      })
    } else if (!isEmpty(PageURL?.parent)) {
      breadcrumbs.push({
        label: PageURL?.parent?.label,
        url: `/${PageURL?.parent?.slug}/`,
      })
      // breadcrumbs.push({ label: PageURL?.label, url: `/${PageURL?.slug}/${PageURL?.parent?.slug}` })
    }
    //  else {
    //   breadcrumbs.push({ label: PageURL?.label, url: `/${PageURL?.slug}` })
    // }
  }
  if (!isEmpty(staticList)) {
    staticList.map(item =>
      breadcrumbs.push({ label: item.Title, url: `/${item.slug}/` })
    )
  }
  if (!isEmpty(PageURL?.label) && isEmpty(staticList)) {
    breadcrumbs.push({ label: PageURL?.label, url: `/${PageURL?.slug}/` })
  }
  // console.log(breadcrumbs)
  return breadcrumbs
}

/**Send Mail*/
export const SendMail = postData => {
  return window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.stringify(postData),
  })
}

/**Form Validation*/
export const FormValidation = (fields, validationFields) => {
  var error = {}
  var isValid = true
  forEach(validationFields, (field, k) => {
    if (fields[field] === null || fields[field] === "") {
      error[field] = true
      isValid = false
    } else {
      if (
        field === "email" &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          fields[field]
        )
      ) {
        error[field] = true
        isValid = false
      } else if (field === "telephone" && fields[field].length <= 8) {
        error[field] = true
        isValid = false
      } else {
        error[field] = false
      }
    }
  })
  if (!isValid) {
    return { status: false, errors: error }
  }
  return { status: true, errors: {} }
}

export const formTracking = ({ event_tracking, form_name }) => {
  // tracking event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "formSubmit",
    formType: "form-" + event_tracking,
    formId: "form-" + event_tracking,
    formName: form_name,
    formLabel: form_name,
  })
}

export const eventTracking = ({ eventName , url }) => {
  // tracking event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
    formType: url,
    formId: eventName,
    formName: eventName,
    formLabel: eventName,
  })
}
