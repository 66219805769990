import React, { useEffect, useState } from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./SearchBox.scss"
import { Tabs, Tab } from "../CustomTabs/CustomTabs"
import { navigate } from "gatsby"
import {
  offplanForSaleURL,
  propertyForRentURL,
  propertyForSaleURL,
} from "../../site/urls"
import $ from "jquery"
import useAreaMutiSelect from "../SearchResults/PredictiveSearch/useAreaMutiSelect"
import SearchAutoSuggest from "../SearchResults/PredictiveSearch/SearchAutoSuggest"
import SelectedAreaList from "../SearchResults/PredictiveSearch/SelectedAreaList"
import { DEFAULT_AREA } from "../SearchResults/utils"
import _ from "lodash"
import { useLocation } from "@react-hooks-library/core"

const TABS = { buy: "buy", rent: "rent", offPlan: "off-plan" }

const SearchBox = () => {
  const [activeKey, setActiveKey] = useState(TABS.buy)

  const location = useLocation()
  const pathname = location?.pathname
  const [searchStateToUrl, setSearchStateToUrl] = useState()

  useEffect(() => {
    if (pathname) {
      if (pathname.includes("off-plan")) {
        setSearchStateToUrl(offplanForSaleURL)
        setActiveKey(TABS.offPlan)
      } else if (pathname.includes("rent")) {
        setSearchStateToUrl(propertyForRentURL)
        setActiveKey(TABS.rent)
      } else {
        setSearchStateToUrl(propertyForSaleURL)
        setActiveKey(TABS.buy)
      }
    }
  }, [location])

  useEffect(() => {
    if (activeKey === TABS.buy) {
      setSearchStateToUrl(propertyForSaleURL)
    } else if (activeKey === TABS.rent) {
      setSearchStateToUrl(propertyForRentURL)
    } else if (activeKey === TABS.offPlan) {
      setSearchStateToUrl(offplanForSaleURL)
    }
  }, [activeKey])

  const { isMobile } = useDeviceMedia()

  const {
    selectedAreas,
    areasSuggestionList,
    handleSelectArea,
    handleRemoveArea,
    handleRemoveAllAreas,
  } = useAreaMutiSelect()

  const onSearch = () => {
    let url = searchStateToUrl
    var keywordareaval = $(".areas-list-comp .react-autosuggest__input").val()

    if (!_.isEmpty(selectedAreas)) {
      url += `in-${selectedAreas.map(area => area.slug).join("-and-")}/`
    }
    // else if(keywordareaval){
    //   url += `in-${keywordareaval?.replace(/\s+/g, "-")?.toLowerCase()}/`
    // }
    else {
      url += `in-${DEFAULT_AREA}/`
    }

    navigate(url)
    
  }

  return (
    <div className="search-box-wrap areas-list-comp">
      <div className="search-box-container">
        <Tabs
          className="search-tab-section"
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        >
          <Tab eventKey={TABS.buy}>buy</Tab>
          <Tab eventKey={TABS.rent}>rent</Tab>
          <Tab eventKey={TABS.offPlan}>off plan</Tab>
        </Tabs>
        <div className="search-section">
          <SearchAutoSuggest
            placeholder={"City, community or area"}
            handleSelectArea={handleSelectArea}
            selectedAreas={selectedAreas}
            areasSuggestionList={areasSuggestionList}
            autosuggestInputProps={{
              className: "react-autosuggest__input refine-autosuggest-input",
            }}
          />
          <button
            className="button button-black search-button"
            onClick={onSearch}
          >
            {isMobile ? <i className="icon icon-search"></i> : "search"}
          </button>
        </div>
        <SelectedAreaList
          selectedAreas={selectedAreas}
          handleRemoveArea={handleRemoveArea}
          handleRemoveAllAreas={handleRemoveAllAreas}
        />
      </div>
    </div>
  )
}

export default SearchBox
